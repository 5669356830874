import { useState, useEffect, useRef } from "react";
import useUserContext from 'src/hooks/useUserContext';
import { windowContext } from 'src/util';
import firebase from "src/util/firebaseWeb";

/**
 * handles authentication state
 * @webonly
 */
function useAuth() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const unsubscribe = useRef<firebase.Unsubscribe>(null)
  const firstRender = useRef<boolean>(true)
  const { updateUser, removeUser } = useUserContext()

  useEffect(() => {
    if (!windowContext()) return

    const setupListener = async () => {
      unsubscribe.current = firebase.auth().onIdTokenChanged(user => {

        if (user) {
          updateUser(user, false)
          firstRender.current = false
          return
        }

        // do not clear the user if its the first render
        // the user might still be in the cookie
        if (!user && !firstRender.current) {
          removeUser()
          firstRender.current = false
          return
        }
      });
    }

    setupListener()

    return () => {
      const unsub = unsubscribe.current
      if (unsub) unsub()
    }
  }, [])

  return {
    isLoading,
    setIsLoading,
  }
}

export default useAuth
