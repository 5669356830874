import { FiMenu } from 'react-icons/fi'
import {
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import Link from 'next/link';
import { routes } from 'src/navigation/util';
import useIsSignedIn from 'src/hooks/useIsSignedIn';
import useUserContext from 'src/hooks/useUserContext';
import useLogout from 'src/hooks/useLogout';

function MobileMenu() {
  const isSignedIn = useIsSignedIn()
  const { account } = useUserContext()
  const { logout, isLoading } = useLogout()

  return (
    <Menu>
      <MenuButton>
        <Icon as={FiMenu} boxSize={6} />
      </MenuButton>
      <MenuList>
        {isSignedIn && (
          <>
            <Link
              href={routes.dashboard}
              passHref
            >
              <MenuItem>
                Dashboard
              </MenuItem>
            </Link>
            <Link
              href={routes.account}
              passHref
            >
              <MenuItem>
                Account
              </MenuItem>
            </Link>
            {
              account?.activeOrg?.id && (
                <Link
                  href={routes.organization(account.activeOrg.id)}
                  passHref
                >
                  <MenuItem>
                    Organization
                  </MenuItem>
                </Link>
              )
            }
            <Link href={routes.newProject} passHref>
              <MenuItem>
                New project
              </MenuItem>
            </Link>
            <MenuDivider />
          </>
        )}
        <Link href={routes.explore} passHref>
          <MenuItem>
            Explore
          </MenuItem>
        </Link>
        <Link href={routes.topics} passHref>
          <MenuItem>
            By topic
          </MenuItem>
        </Link>
        <Link href={routes.countries} passHref>
          <MenuItem>
            By country
          </MenuItem>
        </Link>
        <Link href={routes.projects} passHref>
          <MenuItem>
            Projects
          </MenuItem>
        </Link>
        <Link
          href={routes.companies}
          passHref
        >
          <MenuItem>
            Companies
          </MenuItem>
        </Link>
        {isSignedIn ? (
          <>
            <MenuDivider />
            <MenuItem
              onClick={logout}
              disabled={isLoading}
              color='error.default'
            >
              Sign out
            </MenuItem>
          </>
        ) : (
          <>
            <MenuDivider />
            <Link
              href={routes.login}
              passHref
            >
              <MenuItem
                color='success.default'
              >
                Login
              </MenuItem>
            </Link>
          </>
        )}
      </MenuList>
    </Menu >
  );
}

export default MobileMenu