import analytics from 'src/util/analytics'
import { clearActions } from 'src/util/locallyStoredActions'
import { useRouter } from 'next/router'
import firebase from 'src/util/firebaseWeb'
import useAuth from 'src/auth/useAuth'

function useLogout() {
  const { isLoading, setIsLoading } = useAuth()
  const router = useRouter()

  return {
    isLoading,
    logout: async () => {
      setIsLoading(true)

      await Promise.all([
        firebase.auth().signOut(),
        router.push('/'),
      ])

      clearActions()
      analytics.logEvent('logout')

      setIsLoading(false)
    }
  }
}

export default useLogout
