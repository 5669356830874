export enum ActionTypes {
  'deleteAccount' = 'delete-account',
  'shareProject' = 'share-project',
  'shareOrg' = 'share-org',
  'acceptMembership' = 'accept-membership',
  'redirect' = 'redirect',
}

type AddActionParams = {
  action: ActionTypes.deleteAccount,
  payload?: null,
} | {
  action: ActionTypes.shareProject,
  payload: {
    email: string,
    message: string,
    entityId: string,
  }
} | {
  action: ActionTypes.shareOrg,
  payload: {
    email: string,
    entityId: string,
  }
} | {
  action: ActionTypes.redirect,
  payload: string,
}

const addAction = ({ action, payload }: AddActionParams) => {
  window.localStorage.setItem('pl-action', action)
  let _payload = payload as string
  if (typeof payload !== 'string') _payload = JSON.stringify(payload)
  if (payload) window.localStorage.setItem('pl-action-payload', _payload)
}

const clearActions = () => {
  window.localStorage.removeItem('pl-action')
  window.localStorage.removeItem('pl-action-payload')
}

export {
  addAction,
  clearActions,
}
